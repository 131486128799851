<template>
  <div class="col-md-12">
    <h4 slot="header" class="text-center">
      World Map <br />
      <small>
        Looks great on any resolution. Made by our friends from
        <a target="_blank" rel="noopener" href="http://datamaps.github.io"
          >Data Maps</a
        >.
      </small>
    </h4>

 
    

    <card type="plain">
      <async-world-map class="map map-big" :data="mapData"></async-world-map>
    </card>
  </div>
</template>
<script>
import AsyncWorldMap from 'src/components/WorldMap/AsyncWorldMap.vue';

export default {
  components: {
    AsyncWorldMap
  },
  data() {
    return {
    
      // mapData: {
      //   AU: 760,
      //   BR: 550,
      //   CA: 120,
      //   DE: 1300,
      //   FR: 540,
      //   GB: 690,
      //   GE: 200,
      //   IN: 200,
      //   RO: 600,
      //   RU: 300,
      //   US: 2920
      // },

        selects: {
        simple : '',
        gbs : '',
        licenses : [
           { value: "Quarrel" ,label: 'Quarrel' }, 
           { value: "Gold", label: 'Gold' } 
        ],
        gb: [
          { value: "1" ,label: "1"},
          { value: "2" ,label: "2"},
          { value: "3" ,label: "3"},
          { value: "4" ,label: "4"},
          { value: "5" ,label: "5"},
          { value: "6" ,label: "6"},
          { value: "7" ,label: "7"},
          { value: "8" ,label: "8"},
          { value: "9" ,label: "9"},
          { value: "10" ,label: "10"},
          { value: "11" ,label: "11"},
          { value: "12" ,label: "12"},
          { value: "13" ,label: "13"},
          { value: "14" ,label: "14"},
          { value: "15" ,label: "15"},
          { value: "16" ,label: "16"},
          { value: "17" ,label: "17"},
          { value: "18" ,label: "18"},
          { value: "19" ,label: "19"},
          { value: "Unlimited" ,label: "Unlimited"},
        ]
    },
    };
  }
};
</script>
<style>
#worldMap {
  width: 100%;
}
</style>
