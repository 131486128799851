<template>
  <div class="mt-3">
    <h4 slot="header" class="card-title">{{ $t("EXECUTABLE UPDATE") }}</h4>
    <card class="stacked-form">
      <form @submit.prevent>
        <div>
          <div
            class="flex items-center justify-center w-full h-screen text-center"
            id="app"
          >
            <div
              class="p-12 bg-gray-100 border border-gray-300"
              @dragover="dragover"
              @dragleave="dragleave"
              @drop="drop"
            >
              <input
                type="file"
                multiple
                name="fields[assetsFieldHandle][]"
                id="assetsFieldHandle"
                class="w-px h-px opacity-0 overflow-hidden absolute"
                @change="onChange"
                ref="file"
                accept=".exe"
              />

              <label for="assetsFieldHandle" class="block cursor-pointer">
                <div>{{ $t("Drag & Drop file here") }}...</div>
              </label>

              <ul class="mt-4" v-if="this.filelist.length" v-cloak>
                <div id="preview">
                  <img src="" alt="EXE FILE" height="100px" width="100px" />
                </div>
                <li
                  class="text-sm p-1"
                  v-for="(file, index) in filelist"
                  :key="index"
                  v-show="!type_length"
                >
                  {{ file.name }}
                </li>
                <base-alert type="danger" dismissible v-show="show">
                  <span v-if="type_length"
                    >{{ $t("Number of files selected for upload") }}
                    {{ filelist.length }}
                    {{ $t("exceeds maximum allowed limit of 1") }}.
                    {{ $t("Please retry your upload!") }}</span
                  >
                  <span v-else
                    >{{ $t("Invalid extension for file") }}
                    {{ filelist[0].name }}
                    {{ $t("Only exe files are supported") }}
                  </span>
                </base-alert>
              </ul>
            </div>
          </div>
          <div style="border: 1px solid black">
            <base-button
              @click="chooseFiles()"
              class="mr-3 mt-3 float-right"
              native-type="submit"
              >{{ $t("Browse") }}..</base-button
            >
            <base-button
              @click="submitFile()"
              class="mt-3 float-right"
              native-type="submit"
              type="primary"
              v-show="showupload"
              >{{ $t("Submit") }}</base-button
            >
            <base-button
              @click="remove(filelist.indexOf(file))"
              class="mt-3 float-right"
              native-type="button"
              v-show="showupload"
              type="warning"
              title="Remove file"
              >{{ $t("Remove") }}</base-button
            >
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { BaseAlert } from "src/components";
import swal from "sweetalert2";

export default {
  components: {
    BaseAlert,
  },
  data: () => ({
    filelist: [], // Store our uploaded files
    show: false,
    type_length: false,
    showconfirm: false,
    showupload: false,
    responsesubmit: null,
    responseconfirm: null,
  }),
  methods: {
    onChange() {
      this.filelist = [...this.$refs.file.files];
      this.show = false;
      this.type_length = false;
      this.showconfirm = false;
      this.showupload = true;
      // console.log(this.filelist[0].type);
      if (this.filelist.length != 1) {
        this.show = true;
        this.type_length = true;
        this.showupload = false;
      } else if (this.filelist[0].type != "application/x-ms-dos-executable") {
        this.show = true;
        this.showconfirm = false;
        this.showupload = false;
      }
    },
    remove(i) {
      this.filelist.splice(i, 1);
      this.showconfirm = false;
      this.showupload = false;
      window.location.reload();
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-green-300")) {
        event.currentTarget.classList.remove("bg-gray-100");
        event.currentTarget.classList.add("bg-green-300");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-gray-100");
      event.currentTarget.classList.remove("bg-green-300");
    },
    chooseFiles() {
      document.getElementById("assetsFieldHandle").click();
    },
    async submitFile() {
      try {
        await this.$store.dispatch("updatepackage/uploadexe", this.filelist[0]);
        this.responsesubmit = await this.$store.getters[
          "updatepackage/message"
        ];
        if (this.responsesubmit == "Success") {
          this.$notify({
            type: "success",
            message: this.$t("File upload successfully."),
            icon: "tim-icons icon-bell-55",
          });
          this.showconfirm = true;
        } else {
          this.showconfirm = false;
          this.$notify({
            type: "danger",
            message: this.responsesubmit,
            icon: "tim-icons icon-bell-55",
          });
        }
        setInterval(() => {
          window.location.reload();
        }, 1150);
      } catch (e) {
        this.$notify({
          type: "danger",
          message: this.$t("Oops, something went wrong!"),
          icon: "tim-icons icon-bell-55",
        });
      }
    },
    async confirm() {
      try {
        const confirmation = await swal.fire({
          title: this.$t("Confirm update?"),
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "OK",
          buttonsStyling: false,
        });
        if (confirmation.value === true) {
          await this.$store.dispatch("updatepackage/addexecutable");
          this.responseconfirm = await this.$store.getters[
            "updatepackage/updatepack"
          ];

          if (this.responseconfirm) {
            if (this.responseconfirm.message) {
              this.$notify({
                type: "danger",
                message: this.responseconfirm.message,
                icon: "tim-icons icon-bell-55",
              });
            } else {
              this.$notify({
                type: "success",
                message: this.$t("Update inserted."),
                icon: "tim-icons icon-bell-55",
              });
            }
          } else {
            this.$notify({
              type: "danger",
              message: this.$t("Something went wrong."),
              icon: "tim-icons icon-bell-55",
            });
          }
          setTimeout(() => window.location.reload(), 5000);
        }
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("Request insertion error"),
          icon: "tim-icons icon-bell-55",
        });
      }
    },
  },
};
</script>

<style scoped>
[v-cloak] {
  display: none;
}

.w-px {
  width: 1px;
}

.absolute {
  position: absolute;
}

.opacity-0 {
  opacity: 0;
}

.p-12 {
  padding: 3rem;
}

.border {
  border-width: 1px;
}

.border-gray-300 {
  border-color: #e2e8f0;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>
